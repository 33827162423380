<template>
  <div id="post-modal-data">
    <div>
      <div class="d-flex align-items-center">
        <div class="user-img">
          <img
            src="/images/default/user_profile_picture.jpg"
            alt="userimg"
            class="avatar-60 rounded-circle"
          />
        </div>

        <form class="post-text ml-3 w-100">
          <textarea
            v-model="description"
            id="desc"
            class="form-control rounded"
            placeholder="Ecrire quelque chose ici..."
            style="border: none"
          ></textarea>
          <vue-dropzone
            v-show="dropzoneState"
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-file-added="fileAdded"
            @vdropzone-removed-file="FileRemoved"
          ></vue-dropzone>
        </form>
      </div>
      <hr />
      <ul class="d-flex flex-wrap align-items-center list-inline m-0 p-0">
        <li class="col-md-6 mb-3">
          <div class="iq-bg-primary rounded p-2 pointer mr-3">
            <button
              v-on:click="state"
              style="border:none;color: #48a3e6;background: none;"
            >
              <img
                src="/images/small/07.png"
                alt="icon"
                class="img-fluid"
                v-on:click="state"
              />
              Photo/Video
            </button>
          </div>
        </li>
        <!-- <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/08.png" alt="icon" class="img-fluid" />
                  Tag Friend
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/09.png" alt="icon" class="img-fluid" />
                  Feeling/Activity
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/10.png" alt="icon" class="img-fluid" />
                  Check in
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/11.png" alt="icon" class="img-fluid" />
                  Live Video
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/12.png" alt="icon" class="img-fluid" />
                  Gif
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/13.png" alt="icon" class="img-fluid" />
                  Watch Party
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/14.png" alt="icon" class="img-fluid" />
                  Play with Friends
                </div>
              </li> -->
      </ul>
      <hr />

      <button
        type="submit"
        class="btn btn-primary d-block w-100 mt-3"
        v-on:click="submit"
      >
        Publier
      </button>
    </div>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import $ from "jquery";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    page: {
      default: null,
    },
    groupe: {
      default: null,
    },
    postId: {
      default: null,
    },
  },
  data() {
    return {
      post: null,
      postMedia: null,
      counter: 0,
      description: "",
      form: new FormData(),
      dropzoneState: false,
      dropzoneOptions: {
        url: "/",
        headers: {},
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
    };
  },
  mounted() {
    console.log("Edit Post");
    console.log(this.postId);
    this.getPost(this.postId);
    console.log(this.$refs.myVueDropzone);
  },

  methods: {
    getPost(postId) {
      const config = {
        headers: {
          "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      };
      axios.get("/post/" + postId, config).then((response) => {
        this.post = response.data;
        this.description = this.post.content;
      });
    },
    state: function() {
      if (!this.dropzoneState) {
        this.dropzoneState = true;
        this.init();
      }
    },

    init: function() {
      let $vm = this;

      this.postMedia = this.post.media.map(function(item) {
        return "/" + item["path"];
      });

      this.post.media.forEach(function(item, index) {
        var imgUrl = "/" + item["path"];
        // var mockFile = {
        //   id: 1,
        //   name: img,
        //   type: "image/*"
        // };
        var mockFile = {
          id: item["id"],
          size: 0,
          name: item["path"].split("/").reverse()[0],
          type: "image/*",
        };

        $vm.$refs.myVueDropzone.manuallyAddFile(mockFile, imgUrl);
      });
    },

    fileAdded(file) {
      if (!file.id) {
        this.form.append("files[" + this.counter + "]", file);
        this.counter = this.counter + 1;
      }
    },

    FileRemoved(file) {
      if (file.id) {
        this.form.append("filesRemoved[]", file.id);
      }
      for (var key of this.form.keys()) {
        if (this.form.get(key) === file) {
          this.form.delete(key);
        }
      }
    },

    submit() {
      if (this.description != "") {
        this.form.append("description", this.description);
      }
      this.form.append("group_id", this.groupe);
      this.form.append("page_id", this.page);
      this.form.append("_method", "PUT");

      axios
        .post("/post/" + this.postId, this.form, {
          "X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]")
            .content,
          "Content-Type": "multipart/form-data",
        })
        .then(
          (response) => {
            if (response.status === 200) {
              $("#post-modal").modal("toggle");
              $(".modal-backdrop").remove();
              this.counter = 0;
              this.description = "";
              this.form = new FormData();
              this.dropzoneState = false;
              location.reload();
            }
          },
          (response) => {}
        );
    },
  },
};
</script>
