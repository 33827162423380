<template>
    <div>

        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div class="iq-card-body">
                <div class="post-item">
                    <div class="user-post-data p-3">
                        <div class="d-flex flex-wrap">
                            <div class="media-support-user-img mr-3">
                                <a v-if="post.user" :href="'/profile/visite/'+post.user.slug">
                                    <img class="rounded img-fluid"
                                        :src="post.user.profile_picture_path == null ? 'images/default/user_profile_picture.jpg' : post.user.profile_picture_path"
                                        alt="">
                                </a>
                                <img v-else class="rounded img-fluid"
                                :src="post.user.profile_picture_path == null ? 'images/default/user_profile_picture.jpg' : post.user.profile_picture_path"
                                alt="">
                            </div>
                            <div class="media-support-info mt-2">
                                                            
                                <h5 class="mb-0 d-inline-block"> <a :href="'/profile/visite/'+post.user.slug" class="">{{ post.user.last_name }} {{ post.user.first_name }}</a></h5>  
                                    <span v-if="post.user.type == 'admin'" class="badge badge-danger ml-2">ANPT</span>                                      
                                    <span v-else class="badge badge-success ml-2">Startup</span>                                     
                                                
                                    <p class="mb-0">{{ post.post_date }}</p>
                            </div>
                            <div class="iq-card-post-toolbar" v-if="post.user_id == user.id">
                                <div class="dropdown">
                                    <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false" role="button">
                                        <i class="ri-more-fill"></i>
                                    </span>
                                    <div class="dropdown-menu m-0 p-0">
                                    
                                        <a class="dropdown-item p-3" :href="'/post/'+post.id+'/edit'">
                                            <div class="d-flex align-items-top">
                                                <div class="icon font-size-20"><i class="ri-pencil-line"></i></div>
                                                <div class="data ml-2">
                                                    <h6>Modifier</h6>
                                                    <p class="mb-0">Modifier la publication</p>
                                                </div>
                                            </div>
                                        </a>
                                        
                                        <a class="deletePostBtn dropdown-item p-3" href="#" data-toggle="modal" data-target="#deletePostModal" :data-id="post.id">
                                            <div class="d-flex align-items-top">
                                                <div class="icon font-size-20"><i class="ri-delete-bin-7-line"></i></div>
                                                <div class="data ml-2">
                                                    <h6>Supprimer</h6>
                                                    <p class="mb-0">Supprimer la publication</p>
                                                </div>
                                            </div>
                                        </a>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <p>
                            <span v-html="post.content.substr(0,150)"></span>
                            <div v-if="post.content.length > 150">
                                <span :id="'dots'+post.id">...</span>
                                <span class="more" :id="'more'+post.id" v-html="post.content.substr(150)"></span>
                                <button v-on:click="FunctionReadMore(post.id)" id="myBtn" :data-id="post.id" class="">
                                    Lire plus<i class="ri-arrow-right-s-line"></i>
                                    </button>
                            </div>
                        </p>
                        <!-- Fichier -->
                        <div class="row">
                        <div v-for="file in post.media" :key="file.id" class="col-6 col-sm-3 col-lg-3">
                            <a v-if="file.type_file == 'fichier'"  target="_blank" :href="file.path_url">
                                <img class="img-fluid rounded  z-depth-1" :src="'images/default/file.png'" style="width: 90px;">
                            </a>
                        </div>
                        </div>
                        <!-- Fichier -->
                    </div>
                    <div class="user-post">
                        <div v-if="post.media.length != 0">

                                <div v-if="post.media.length === 1" class="row" id="gallery" data-toggle="modal"
                                    :data-target="'#exampleModal-'+post.id">
                                    <div class="col-12 " v-if="post.media[0].type_file == 'image'" >
                                        <img class="img-fluid rounded w-100 z-depth-1"
                                            style="height:500px; object-fit:cover"
                                            :src="post.media[0].path_url"
                                            :data-target="'#carouselExample-'+post.id " data-slide-to="0">
                                        
                                    </div>
                                </div>

                                <div v-else-if="post.media.length === 2" class="row" id="gallery" data-toggle="modal"
                                    :data-target="'#exampleModal-'+post.id">
                                    <div class="col-12 col-sm-6 col-lg-6" v-if="post.media[0].type_file == 'image'">
                                        <img class="img-fluid rounded w-100 z-depth-1"
                                            style="height:250px; object-fit:cover"
                                            :src="post.media[0].path_url"
                                            :data-target="'#carouselExample-'+post.id" data-slide-to="0">
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6" v-if="post.media[1].type_file == 'image'">
                                        <img  class="img-fluid rounded w-100 z-depth-1"
                                            style="height:250px; object-fit:cover"
                                            :src="post.media[1].path_url"
                                            :data-target="'#carouselExample-'+post.id" data-slide-to="1">
                                    </div>

                                </div>
                                <div v-else class="row" id="gallery" data-toggle="modal"
                                    :data-target="'#exampleModal-'+post.id">
                                    <div class="col-12 col-sm-6 col-lg-6" v-if="post.media[0].type_file == 'image'">
                                        <img class="img-fluid rounded w-100 z-depth-1"
                                            style="height:500px; object-fit:cover"
                                            :src="post.media[0].path_url"
                                            :data-target="'#carouselExample-'+post.id" data-slide-to="0">
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6">
                                        <div class="col-12 col-sm-12 col-lg-12" v-if="post.media[1].type_file == 'image'">
                                            <img class="img-fluid rounded w-100 z-depth-1"
                                                style="height:250px; object-fit:cover"
                                                :src="post.media[1].path_url "
                                                :data-target="'#carouselExample-'+post.id" data-slide-to="1">
                                        </div>
                                        <div class="col-12 col-sm-12 col-lg-12 mt-1">
                                                <img v-if="post.media.length === 3 && post.media[2].type_file == 'image'" class="img-fluid rounded w-100 z-depth-1"
                                                    style="height:250px; object-fit:cover"
                                                    :src="post.media[2].path_url "
                                                    :data-target="'#carouselExample-'+post.id" data-slide-to="2">
                                                <div v-else >
                                                    <img v-if="post.media[2].type_file == 'image'" class="img-fluid rounded w-100 z-depth-1"
                                                        style="height:250px; object-fit:cover"
                                                        :src="post.media[2].path_url"
                                                        :data-target="'#carouselExample-'+post.id" data-slide-to="2">


                                                    <div class="centered"
                                                        style="position:absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">
                                                        <a class="iq-icons-list" href="#"
                                                            style="color: white; margin-bottom: 0px; font-size: 30px;"><b><i
                                                                    class="ri-add-line pr-2"
                                                                    style="width:25px; margin-right:0px"></i>{{ post.media.length - 3 }}</b></a>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>

                                </div>

                        </div>
                    </div>
                    <div class="comment-area mt-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="like-block position-relative d-flex align-items-center">
                                <div class="d-flex align-items-center">
                                    <div class="like-data">
                                        <!-- raection -->
                                        <reaction-component  v-if="post.reaction.length != 0" :props1="JSON.stringify(reaction)"
                                            :props2="post.reaction.length"
                                            :props3="JSON.stringify(userReaction)"
                                            :props4="post.id" />
                                        <reaction-component v-else :props1="JSON.stringify(reaction)" props2="" props3=""
                                            :props4="post.id" />
                                        <!-- end reaction -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- create comment -->
                        <hr>
                        <comment-post :props1="JSON.stringify(post.comments)" :props2="post.id.toString()" />
                        <!-- end create comment -->
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" :id="'exampleModal-'+post.id" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div :id="'carouselExample-'+post.id" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div v-for="(media, index) in post.media" :key="media.id">
                                    <div class="carousel-item" :class="{ active: index ==  currentIndexSlide }">
                                        <img class="d-block w-100" :src="media.path_url">
                                    </div>
                                </div>
                            </div>
                            <div v-if="post.media.length === 1"></div>
                            <div v-else>
                                <a v-on:click="prev" class="carousel-control-prev" :href="'#carouselExample-'+post.id" role="button"
                                    data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a v-on:click="next" class="carousel-control-next" :href="'#carouselExample-'+post.id" role="button"
                                    data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    props: {
        post : {
            type: Object,
            default : null
        },
        user : {
            type: Object,
            default : null
        }
    },
    data(){
        return {
            reaction : {
                0 : 'images/icon/01.png',
                1 : 'images/icon/02.png',
                2 : 'images/icon/03.png',
                3 : 'images/icon/04.png',
                4 : 'images/icon/05.png',
                5 : 'images/icon/06.png',
                6 : 'images/icon/07.png',
            },
            userReaction : [],
            currentIndexSlide : 0,
        }
    },
    beforeMount(){
        if (this.post.reaction.length != 0){
            this.post.reaction.forEach(item => {
                this.userReaction.push(item.user); 
            });
        }

    },
    methods: {
        FunctionReadMore : function(id) {
    
            var dots = document.getElementById("dots"+id);
            var moreText = document.getElementById("more"+id);
            var btnText = document.getElementById("myBtn");

            if (dots.style.display === "none") {
                dots.style.display = "inline";
                btnText.innerHTML = "Lire plus";
                moreText.style.display = "none";
            } else {
                dots.style.display = "none";
                    btnText.innerHTML = "Lire moins";
                //myBtn.style.display = "none";
                moreText.style.display = "inline";
            }
        },
        next : function(){
            if(this.currentIndexSlide < this.post.media.length - 1){
                this.currentIndexSlide++;
            }
            else{
                this.currentIndexSlide = 0;
            }

        },
        prev : function(){
            if(this.currentIndexSlide > 0){
                this.currentIndexSlide--;
            }
            else{
                this.currentIndexSlide = this.post.media.length - 1;
            }

        }
    }
}
</script>
<style lang="">
    
</style>