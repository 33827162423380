var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.rooms.length != 0)?_c('ul',{staticClass:"iq-chat-ui nav flex-column nav-pills"},[_c('p',[_vm._v("Conversations")]),_vm._v(" "),_vm._l((_vm.rooms),function(item){return _c('li',{key:item.room_id,on:{"click":function($event){return _vm.openChatBox(item.room)}}},[_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"avatar mr-2"},[(item.room.status == 'group')?_c('img',{staticClass:"avatar-50",attrs:{"src":'/images/default/groupe.png',"alt":"chatuserimage"}}):_vm._e(),_vm._v(" "),(
                  item.room.status == 'private' &&
                  item.room.friend.profile_picture_path
                )?_c('img',{staticClass:"avatar-50",attrs:{"src":item.room.friend.profile_picture_path,"alt":"chatuserimage"}}):_vm._e(),_vm._v(" "),(
                  item.room.status == 'private' &&
                  !item.room.friend.profile_picture_path
                )?_c('img',{staticClass:"avatar-50",attrs:{"src":'/images/default/user_profile_picture.jpg',"alt":"chatuserimage"}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"chat-sidebar-name"},[(item.room.status == 'private')?_c('h6',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(item.room.friend.first_name)+"\n                "+_vm._s(item.room.friend.last_name)+"\n              ")]):_vm._e(),_vm._v(" "),(item.room.status == 'group')?_c('h6',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(item.room.name)+"\n              ")]):_vm._e(),_vm._v(" "),(item.room.last_chat.read_at != null)?_c('span',[_vm._v(_vm._s(item.room.last_chat.content))]):_vm._e(),_vm._v(" "),(
                  item.room.last_chat.read_at == null &&
                  item.room.last_chat.sender_id != _vm.user.id
                )?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.room.last_chat.content))]):_vm._e(),_vm._v(" "),(
                  item.room.last_chat.read_at == null &&
                  item.room.last_chat.sender_id == _vm.user.id
                )?_c('span',[_vm._v(_vm._s(item.room.last_chat.content))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"chat-meta float-right text-center mt-2 mr-1"},[(item.room.last_chat.read_at == null &&
                  item.room.last_chat.sender_id != _vm.user.id)?_c('div',{staticClass:"chat-msg-counter bg-primary text-white"}):_vm._e()])])])])}),_vm._v(" "),_c('infinite-loading',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-disabled":"busy","infinite-scroll-distance":"10"},on:{"infinite":_vm.infiniteHandler}})],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }