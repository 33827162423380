<template>
  <div>
    <div class="d-flex justify-content-end">
      <button
        v-if="!last_page"
        v-on:click="loadMore()"
        type="button"
        style="color: var(--iq-primary) !important;background: white;border: none;"
      >
        Chargé les commentaire
      </button>
    </div>
    <ul class="post-comments p-0 m-0">
      <li class="mb-2" v-for="item in comments" :key="item.id">
        <div class="d-flex flex-wrap">
          <div class="user-img">
            <img
              v-if="item.user.profile_picture_path"
              v-bind:src="item.user.profile_picture_path"
              v-bind:alt="item.user.last_name"
              class="avatar-35 img-fluid"
            />
            <img
              v-else
              class="avatar-50"
              src="/images/default/user_profile_picture.jpg"
            />
          </div>
          <div class="comment-data-block ml-3">
            <h6><a :href="'/profile/visite/' + item.user.slug">{{ item.user.last_name }} {{ item.user.first_name }}</a></h6>

            <p class="mb-0">{{ item.content }}</p>
            <div class="d-flex flex-wrap align-items-center comment-activity">
              <!-- <a href="javascript:void();">like</a>
              <a href="javascript:void();">reply</a>
              <a href="javascript:void();">translate</a> -->
              <span>{{ item.comment_date }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <form
      class="comment-text d-flex align-items-center mt-3"
      action="javascript:void(0);"
    >
      <input
        class="form-control rounded"
        type="text"
        v-model="comment"
        v-on:keyup.enter="send"
        cols="30"
        placeholder="..."
      />
      <!-- </textarea> -->

      <!-- <div class="comment-attagement d-flex"> -->
        <!-- <a href="javascript:void();"><i class="ri-link mr-3"></i></a> -->
        <!-- <a href="javascript:void();"><i class="ri-user-smile-line mr-3"></i></a> -->
        <!-- <a href="javascript:void();"><i class="ri-camera-line mr-3"></i></a> -->
      <!-- </div> -->
    </form>
  </div>
</template>

<script>
export default {
  props: {
    props1: {
      type: String,
      default: null,
    },
    props2: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      comments: {
        type: Array,
        default: [],
      },
      post: null,
      comment: "",
      next_page: 2,
      last_page: false,
    };
  },

  beforeMount() {
    this.comments = JSON.parse(this.props1);
    this.post = parseInt(this.props2);
  },

  mounted() {
    Echo.private(`comment.${this.post}`).listen("NewComment", (e) => {
      console.log("comment");
      this.comments.push(e.comment.original);
    });
  },

  methods: {
    send() {
      if (this.comment == "") {
        return;
      }

      const config = {
        headers: {
          // "content-type": "multipart/form-data",
          "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      };
      axios
        .post(
          "/comment",
          {
            post_id: this.post,
            content: this.comment,
          },
          config
        )
        .then((response) => {
          // this.comments.push(response.data);
          this.comment = "";
        });
    },
    loadMore() {
      axios
        .get("/post/" + this.post + "?page=" + this.next_page)
        .then((response) => {
          console.log(response.data.comments);
          if (response.data.comments.length != 0) {
            this.next_page = this.next_page + 1;
            this.comments = this.comments.concat(response.data.comments);
          } else if (response.data.comments.length == 0) {
            this.last_page = true;
          }
        });
    },
  },
};
</script>
