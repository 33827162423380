<template>
    <div>
        <!-- <span v-if="this.status == true " class=" badge dots badge-vue">1</span> -->
      <div v-if="this.status == true &&  notification.sender_id != this.idUser" 
        class="toast fade show border-0 toast-msg" role="alert" aria-live="assertive" aria-atomic="true" 
        style=" position: fixed; z-index: 50; left: 100px;top: 100px;">
        <div class="toast-header">
            <svg style="cursor: pointer;" class="bd-placeholder-img rounded mr-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
              <rect width="100%" height="100%" fill="#189AB4 "></rect>
            </svg>
            <strong class="mr-auto">
              <a :href="'/chat/'">Nouveau message</a>
            </strong>
            <button type="button" class="ml-2 mb-1 close " v-on:click="close()">
            <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="toast-body">
            {{notification.content}}
        </div>
      </div>
    </div>
</template>


<script>
  export default {
    
    name: 'NotificationsMsg',
    props: {
      idUser: {
          default: null,
      },
    },
    data () {
      return{
        notification: null,
        status: false,
        rooms:null
      }
    },

    beforeMount () {
    },

    mounted () {  
      
      this.getFriends()
    },

    components: {  },

    computed: {  },

    methods: {
      
      getFriends: function () {
        axios.get("/getRooms").then((response) => {
          if (response.data.data.length != 0) {
            this.rooms = response.data.data;

            this.rooms.forEach(room => {
              Echo.private([`message.${room.room_id}`]).listen('SendMessageEvent', (e) => {
                this.notification = e.message.original;
                this.status = true;
                console.log(this.notification);
              });
            });
          }
        });  
      },

      close(){
        this.status = false;
      }

    }
    
  }
</script>

<style>
.badge-vue{
  background-color:red;
  height: 15px !important;
  width: 15px !important;
  border: solid red;
}
.toast-msg{
  background-color: white;
  border-left: solid 4px #189AB4 !important;
  color: black !important;
}

</style>