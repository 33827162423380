<template>
    <div>
      <div v-if="this.status == true"  class="toast fade show border-0 toast-msg" v-bind:class="type" role="alert" aria-live="assertive" aria-atomic="true"  style=" position: fixed; z-index: 99999; left: 100px;top: 100px;">
        <div class="toast-header" v-bind:class="type">
            <svg style="cursor: pointer;" class="bd-placeholder-img rounded mr-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
              <rect width="100%" height="100%"></rect>
            </svg>
            <strong class="mr-auto">{{ this.title }}</strong>
            <button type="button" class="ml-2 mb-1 close " v-on:click="close()">
            <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="toast-body">
            {{notification.content}}
        </div>
      </div>
    </div>
</template>


<script>
  export default {

    name: 'Toast',
    data () {
      return{
        notification: null,
        status: false,
        type : 'default',
        titlle : null
      }
    },

    beforeMount () {
    },

    mounted () {
        this.$root.$on('showToast',(type, title, message) => {
            this.type = type;
            this.title = title;
            this.notification = {
                content : message
            }
            this.status = true;
        })
    },

    components: {  },

    computed: {  },

    methods: {
      close(){
        this.status = false;
      }
    }
    
  }
</script>

<style>
.badge-vue{
  background-color:red;
  height: 15px !important;
  width: 15px !important;
  border: solid red;
}
.toast-msg{
  background-color: white;
  border-left: solid 4px #189AB4;
  color: black !important;
}

.toast-msg.danger{
  border-left: solid 4px #FF4136 !important;
}

.toast-header svg{
  fill : #189AB4;
}
.toast-header.danger svg{
  fill : #FF4136;
}
</style>