<template>
  <div>
    <div>
      <ul v-if="rooms.length != 0" class="iq-chat-ui nav flex-column nav-pills">
        <p>Conversations</p>
        <li
          v-for="item in rooms"
          :key="item.room_id"
          v-on:click="openChatBox(item.room)"
        >
          <a href="#">
            <div class="d-flex align-items-center">
              <div class="avatar mr-2">
                <img
                  v-if="item.room.status == 'group'"
                  :src="'/images/default/groupe.png'"
                  alt="chatuserimage"
                  class="avatar-50"
                />
                <img
                  v-if="
                    item.room.status == 'private' &&
                    item.room.friend.profile_picture_path
                  "
                  :src="item.room.friend.profile_picture_path"
                  alt="chatuserimage"
                  class="avatar-50"
                />
                <img
                  v-if="
                    item.room.status == 'private' &&
                    !item.room.friend.profile_picture_path
                  "
                  :src="'/images/default/user_profile_picture.jpg'"
                  alt="chatuserimage"
                  class="avatar-50"
                />
              </div>
              <div class="chat-sidebar-name">
                <h6 v-if="item.room.status == 'private'" class="mb-0">
                  {{ item.room.friend.first_name }}
                  {{ item.room.friend.last_name }}
                </h6>
                <h6 v-if="item.room.status == 'group'" class="mb-0">
                  {{ item.room.name }}
                </h6>
                <span v-if="item.room.last_chat.read_at != null">{{
                  item.room.last_chat.content
                }}</span>
                <span
                  v-if="
                    item.room.last_chat.read_at == null &&
                    item.room.last_chat.sender_id != user.id
                  "
                  style="font-weight: bold"
                  >{{ item.room.last_chat.content }}</span
                >
                <span
                  v-if="
                    item.room.last_chat.read_at == null &&
                    item.room.last_chat.sender_id == user.id
                  "
                  >{{ item.room.last_chat.content }}</span
                >
              </div>
              <div class="chat-meta float-right text-center mt-2 mr-1">
                <div
                  v-if="item.room.last_chat.read_at == null &&
                    item.room.last_chat.sender_id != user.id"
                  class="chat-msg-counter bg-primary text-white"
                ></div>
              </div>
            </div>
          </a>
        </li>
        <infinite-loading
          v-infinite-scroll="loadMore"
          infinite-scroll-disabled="busy"
          infinite-scroll-distance="10"
          @infinite="infiniteHandler"
        >
        </infinite-loading>
      </ul>
    </div>
  </div>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";
export default {
  name: "listConversation",
  props: {
    pUser: {
      default: null,
    },
  },
  data() {
    return {
      user: JSON.parse(this.pUser),
      rooms: [],
      page: 2,
      current_page: null,
      next_page: 0,
      last_page: null,
      users: [],
      newRoom: {
        name: null,
        status: null,
      },
      roomUser: {
        user_id: null,
        room_id: null,
        status: null,
      },
    };
  },

  beforeMount() {},

  mounted() {
    this.getFriends();
    // setTimeout(() => {
      // this.rooms.forEach((room) => {
      //   console.log("room");
      //   Echo.private([`message.${room.room_id}`]).listen(
      //     "SendMessageEvent",
      //     (e) => {
      //       console.log("hiii msg");
      //       console.log(e.message.original);
      //       this.badge_room = e.message.original.room_id;
      //       if (room.room_id == e.message.original.room_id) {
      //         room.badge = 1;
      //       }
      //       console.log(room);
      //       this.getFriends();
      //     }
      //   );
      // });
    // }, 2000);
  },
  components: {
    InfiniteLoading,
  },
  computed: {},

  methods: {
    getFriends: function () {
      axios.get("/getRooms").then((response) => {
        if (response.data.data.length != 0) {
          this.current_page = response.data.current_page;
          this.next_page = response.data.current_page + 1;
          this.last_page = response.data.last_page;
          this.rooms = response.data.data;
                this.rooms.forEach((room) => {
                  Echo.private([`message.${room.room_id}`]).listen(
                    "SendMessageEvent",
                    (e) => {
                      console.log("hiii msg");
                      console.log(e.message.original);
                      this.badge_room = e.message.original.room_id;
                      if (room.room_id == e.message.original.room_id) {
                        room.badge = 1;
                      }
                      console.log(room);
                      this.getFriends();
                    }
                  );
                });
        }
      });
    },

    openChatBox(room) {
      console.log(room);
      this.$root.$emit("boxConversation", room);
    },

    infiniteHandler($state) {
      setTimeout(() => {
        axios.get("/getRooms?page=" + this.next_page).then((response) => {
          if (response.data.data.length != 0) {
            this.current_page = response.data.current_page;
            console.log(response);
            this.next_page = response.data.current_page + 1;
            this.last_page = response.data.last_page;

            this.rooms = this.rooms.concat(response.data.data);
            if (this.current_page == this.last_page) {
              $state.complete();
            }
            $state.loaded();
          }
          if (response.data.data.length == 0) {
            $state.complete();
          }
        });
      }, 1000);
    },
  },
};
</script>
