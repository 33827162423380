<template>
    <div>
        <button v-if="this.status == 'annuler' " v-on:click="annuler()" type="submit" class="mr-3 btn btn-block btn-primary rounded">
            <i class="ri-delete-bin-5-line"></i>
            Annuler
        </button>
        <button v-if="this.status == 'ajouter' " v-on:click="ajouter()" type="submit" class="mr-3 btn btn-block btn-primary rounded">
            <i class="ri-user-follow-line"></i>
            Participer
        </button>
    </div>
</template>


<script>
export default {
  
  name: 'btnParticipateTraining',
  props: {
        idTraining: {
            default: null,
        },
        idUser: {
            default: null,
        },
  },
  data () {
    return{
      status: null,
      relation: null,   
    }
  },

  beforeMount () {
  },

  mounted () {
    this.getRelation(this.idTraining, this.idUser);
  },

  components: {
    
  },
  computed: {
    
  },

  methods: {

    getRelation(idTraining, idUser){
        const config = {
            headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
                .content,
            },
        };
        axios.post("/get-relation-training", {idTraining:idTraining, idUser:idUser} , config)
            .then((response) => {
            if(response.data.length == 0){
                this.status = 'ajouter';
            }else{
                this.relation = response.data[0];
                this.status = 'annuler';
            }

        });
    },

    ajouter(){
        const config = {
            headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
                .content,
            },
        };
        axios.post("/add-relation-training", {idTraining:this.idTraining, idUser:this.idUser} , config)
            .then((response) => {
            this.relation = response.data;
            this.getRelation(this.idTraining, this.idUser);
        });

    },
    annuler(){
        const config = {
            headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
                .content,
            },
        };

        axios.delete("/delete-relation-training/"+this.relation.id , config)
            .then((response) => {
                this.getRelation(this.idTraining, this.idUser);
        });
    },

  }
  
}
</script>