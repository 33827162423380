<template>
    <div>
      <span v-if="this.status == true &&  notification.sender_id != this.idUser " class=" badge dots badge-vue">1</span>
    </div>
</template>


<script>
  export default {
    
    name: 'NotificationsMsgNav',
    props: {
      idUser: {
          default: null,
      },
    },
    data () {
      return{
        notification: null,
        status: false,
        rooms:null
      }
    },

    beforeMount () {
    },

    mounted () {  
      
      this.getFriends()
    },

    components: {  },

    computed: {  },

    methods: {

      
      getFriends: function () {
        axios.get("/getRooms").then((response) => {
          if (response.data.data.length != 0) {
            this.rooms = response.data.data;

            this.rooms.forEach(room => {
              Echo.private([`message.${room.room_id}`]).listen('SendMessageEvent', (e) => {
                this.notification = e.message.original;
                this.status = true;
                console.log(this.notification);
              });
            });
          }
        });  
      },

      close(){
        this.status = false;
      }

    }
    
  }
</script>

<style>
.badge-vue{
  background-color:red;
  height: 15px !important;
  width: 15px !important;
  border: solid red;
}
.toast-msg{
  background-color: white;
  border-left: solid 4px #189AB4 !important;
  color: black !important;
}

</style>