<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Example Component</div>

          <div class="card-body">
            I'm an example component.
            <button v-on:click="send">send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log("Component mounted.");

    Echo.private(`test.${this.user.id}`).listen("Test", (e) => {
      console.log('test')
       console.log(this.user.id)
      console.log( e.message);
    });
  },
  methods: {
    send: function () {
      axios
        .post("/test/1", this.user.id, {
          "X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]")
            .content,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("response 200");
            }
          },
          (response) => {}
        );
    },
  },
};
</script>

