<template>
  <div
    v-if="isShowing"
    class="tab-pane fade active show"
    id="chatbox2"
    role="tabpanel"
    style="
      width: 350px;
      margin-left: 100px;
      position: fixed;
      bottom: 0;
      z-index: 99999;
      background: #fff;
    "
  >
    <div class="chat-head">
      <header
        class="
          d-flex
          justify-content-between
          align-items-center
          bg-white
          pt-3
          pl-3
          pr-3
          pb-3
        "
      >
        <!-- header conversation -->

        <div class="d-flex align-items-center">
          <div class="sidebar-toggle">
            <i class="ri-menu-3-line"></i>
          </div>
          <div class="avatar chat-user-profile m-0 mr-3">
            <img
              :src="user.profile_picture_path"
              :alt="user.first_name"
              class="avatar-50"
            />
            <!-- <span class="avatar-status"
              ><i class="ri-checkbox-blank-circle-fill text-success"></i
            ></span> -->
          </div>
          <h5 class="mb-0">{{ user.first_name }} {{ user.last_name }}</h5>
        </div>

        <div class="chat-user-detail-popup scroller">
          <div class="user-profile text-center">
            <button type="submit" class="close-popup p-3">
              <i class="ri-close-fill"></i>
            </button>
            <div class="user mb-4">
              <a class="avatar m-0">
                <img :src="user.profile_picture_path" :alt="user.first_name" />
              </a>
              <div class="user-name mt-4">
                <h4>{{ user.first_name }} {{ user.last_name }}</h4>
              </div>
              <div v-if="user.country" class="user-desc">
                <p>{{ user.country }}</p>
              </div>
            </div>
            <hr />
            <div class="chatuser-detail text-left mt-4">
              <!-- <div class="row">
                <div class="col-6 col-md-6 title">Bni Name:</div>
                <div class="col-6 col-md-6 text-right">Mark</div>
              </div>
              <hr /> -->
              <div v-if="user.mobile" class="row">
                <div class="col-6 col-md-6 title">Tel:</div>
                <div class="col-6 col-md-6 text-right">{{ user.mobile }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-6 col-md-6 title">Date Of Birth:</div>
                <div class="col-6 col-md-6 text-right">{{ user.date_naissance }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-6 col-md-6 title">Gender:</div>
                <div class="col-6 col-md-6 text-right">{{ user.gender }}</div>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div class="chat-header-icons d-flex">
          <button
            v-on:click="close()"
            class="chat-icon-delete"
            style="
              width: 40px;
              height: 40px;
              font-size: 20px;
              margin: 0 5px 0 0;
              color: var(--iq-primary);
              display: inline-block;
              text-align: center;
              line-height: 40px;
              border-radius: 10px;
              cursor: pointer;
              border: none;
              background: none;
            "
          >
            <i class="ri-close-line"></i>
          </button>
        </div>
      </header>
    </div>
    
    <div class="chat-content scroller">
      <div class="chat">
        <div class="chat-user">
          <a class="avatar m-0">
            <img src="images/user/1.jpg" alt="avatar" class="avatar-35" />
          </a>
          <span class="chat-time mt-1">6:45</span>
        </div>
        <div class="chat-detail">
          <div class="chat-message">
            <p>How can we help? We're here for you! 😄</p>
          </div>
        </div>
      </div>
      <div class="chat chat-left">
        <div class="chat-user">
          <a class="avatar m-0">
            <img src="images/user/06.jpg" alt="avatar" class="avatar-35" />
          </a>
          <span class="chat-time mt-1">6:48</span>
        </div>
        <div class="chat-detail">
          <div class="chat-message">
            <p>Hey John, I am looking for the best admin template.</p>
            <p>Could you please help me to find it out? 🤔</p>
          </div>
        </div>
      </div>
      <div class="chat">
        <div class="chat-user">
          <a class="avatar m-0">
            <img src="images/user/1.jpg" alt="avatar" class="avatar-35" />
          </a>
          <span class="chat-time mt-1">6:49</span>
        </div>
        <div class="chat-detail">
          <div class="chat-message">
            <p>Absolutely!</p>
            <p>
              SocialV Dashboard is the responsive bootstrap 4 admin template.
            </p>
          </div>
        </div>
      </div>
      <div class="chat chat-left">
        <div class="chat-user">
          <a class="avatar m-0">
            <img src="images/user/06.jpg" alt="avatar" class="avatar-35" />
          </a>
          <span class="chat-time mt-1">6:52</span>
        </div>
        <div class="chat-detail">
          <div class="chat-message">
            <p>Looks clean and fresh UI.</p>
          </div>
        </div>
      </div>
      <div class="chat">
        <div class="chat-user">
          <a class="avatar m-0">
            <img src="images/user/1.jpg" alt="avatar" class="avatar-35" />
          </a>
          <span class="chat-time mt-1">6:53</span>
        </div>
        <div class="chat-detail">
          <div class="chat-message">
            <p>Thanks, from ThemeForest.</p>
          </div>
        </div>
      </div>
      <div class="chat chat-left">
        <div class="chat-user">
          <a class="avatar m-0">
            <img src="images/user/06.jpg" alt="avatar" class="avatar-35" />
          </a>
          <span class="chat-time mt-1">6:54</span>
        </div>
        <div class="chat-detail">
          <div class="chat-message">
            <p>I will purchase it for sure. 👍</p>
          </div>
        </div>
      </div>
      <div class="chat">
        <div class="chat-user">
          <a class="avatar m-0">
            <img src="images/user/1.jpg" alt="avatar" class="avatar-35" />
          </a>
          <span class="chat-time mt-1">6:56</span>
        </div>
        <div class="chat-detail">
          <div class="chat-message">
            <p>Okay Thanks..</p>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-footer p-3 bg-white">
      <form class="d-flex align-items-center" action="javascript:void(0);">
        <div class="chat-attagement d-flex">
          <a href="javascript:void();"
            ><i class="fa fa-smile-o pr-3" aria-hidden="true"></i
          ></a>
          <a href="javascript:void();"
            ><i class="fa fa-paperclip pr-3" aria-hidden="true"></i
          ></a>
        </div>
        <input
          type="text"
          class="form-control mr-3"
          placeholder="Type your message"
        />
        <button
          type="submit"
          class="btn btn-primary d-flex align-items-center p-2"
        >
          <i class="fa fa-paper-plane-o" aria-hidden="true"></i
          ><span class="d-none d-lg-block ml-1">Send</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: null,
      isShowing: false,
    };
  },
  mounted() {
    this.$root.$on("showChatBox", (user) => {
      this.user = user;
      this.isShowing = true;
    });
  },
  methods: {
    close() {
      this.isShowing = false;
    },
  },
};
</script>
