<template>
    <div>
        <span v-if="this.status == true " class=" badge dots badge-vue">1</span>
    </div>
</template>


<script>
  export default {
    
    name: 'Notifications',
    props: {
          idUser: {
              default: null,
          },
    },
    data () {
      return{
        notification: null,
        status: false,
      }
    },

    beforeMount () {
    },

    mounted () {
        console.log(this.idUser);
        Echo.private(`notification.${this.idUser}`)
        .listen('SendNotificationEvent', (e) => {
          console.log(e);
          this.notification = e.notification.original;
          console.log(this.notification);
          this.status = true;
        });


      // Echo.private('App.User.' + this.idUser)
      // .notification((notification) => {
      //     console.log(notification.type);
      // });
    },

    components: {
    },
    computed: {
    },

    methods: {

        ajouter(){
            console.log('notification');
            this.status = false;
                  Echo.private('App.User.' + this.idUser)
      .notification((notification) => {
          console.log(notification.type);
      });
        }
    }
    
  }
</script>

<style>
.badge-vue{
  background-color:red;
  height: 15px !important;
  width: 15px !important;
  border: solid red;
}

</style>