<template>
    <div>
        <button v-if="this.status == 'friend' " v-on:click="supprimer()" type="submit" class="mr-3 btn btn-primary rounded">
            <i class="ri-delete-bin-5-line"></i>
            Supprimer
        </button>
        <button v-if="this.status == 'accepter' " v-on:click="accepter()" type="submit" class="mr-3 btn btn-primary rounded">
            <i class="ri-user-follow-line"></i>
            Accepter
        </button>
        <button v-if="this.status == 'accepter' " v-on:click="annuler()" type="submit" class="mr-3 btn btn-primary rounded">
            <i class="ri-delete-bin-5-line"></i>
            Annuler
        </button>
        <button v-if="this.status == 'annuler' " v-on:click="annuler()" type="submit" class="mr-3 btn btn-primary rounded">
            <i class="ri-delete-bin-5-line"></i>
            Annuler
        </button>
        <button v-if="this.status == 'ajouter' " v-on:click="ajouter()" type="submit" class="mr-3 btn btn-primary rounded">
            <i class="ri-user-follow-line"></i>
            Ajouter
        </button>
    </div>
</template>


<script>
export default {
  
  name: 'btnRelation',
  props: {
        idUser: {
            default: null,
        },
  },
  data () {
    return{
      status: null,
      relation: null,   
    }
  },

  beforeMount () {
  },

  mounted () {
    this.getRelation(this.idUser)
  },

  components: {
    
  },
  computed: {
    
  },

  methods: {
    getRelation(id) {
        axios.get("/get-relation/"+id).then((response) => {

            if(response.data.length == 0){
                this.status = 'ajouter';
            }else{
                this.relation = response.data[0];
                if(this.relation.status == 'friend'){
                    this.status = 'friend';
                }if(this.relation.status == 'waiting'){
                    if(this.relation.sender_id == id){
                        this.status = 'accepter';
                    }else{
                        this.status = 'annuler';
                    }
                }
            }
            console.log(this.status);
        })
    },

    accepter(){
        this.relation.status = 'friend';
        const config = {
            headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
                .content,
            },
        };

        axios.post("/accept-inv", this.relation , config)
            .then((response) => {
                console.log(response.data);
                this.relation
                this.status = "friend"
        });
    },

    ajouter(){
        const config = {
            headers: {
            "X-BtnRelationCSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
                .content,
            },
        };
        axios.post("/add-relation", {id:this.idUser} , config)
            .then((response) => {
            console.log(response.data);
            this.relation = response.data;
            this.status = "annuler";
        });

    },
    annuler(){
        console.log("annuler");
        console.log(this.relation.id);
        const config = {
            headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
                .content,
            },
        };

        axios.delete("/delete-relation/"+this.relation.id , config)
            .then((response) => {
                this.status = "ajouter"
            console.log(response.data);
        });
    },
    supprimer(){
        const config = {
            headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
                .content,
            },
        };
        axios.delete("/delete-relation/"+this.relation.id , config)
            .then((response) => {
                this.status = "ajouter"
            console.log(response.data);
        });
    },
}
}
</script>