/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
Vue.use(require('vue-resource'));
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

Vue.component('freinds-list', require('./components/FreindsList.vue').default);

Vue.component('comment-post', require('./components/CommentPost.vue').default);

Vue.component('new-post', require('./components/NewPost.vue').default);

Vue.component('edit-post', require('./components/EditPost.vue').default);

Vue.component('chat-box', require('./components/ChatBoxComponent.vue').default);

Vue.component('reaction-component', require('./components/ReactionComponent.vue').default);

Vue.component('conversation-list', require('./components/conversation/listConversation.vue').default);

Vue.component('conversation-box', require('./components/conversation/boxConversation.vue').default);

// Vue.component('InfiniteLoading', require('vue-infinite-loading'));

Vue.component('btn-relation', require('./components/BtnRelation.vue').default);

Vue.component('btn-participate-event', require('./components/BtnParticipateEvent.vue').default);
Vue.component('btn-participate-training', require('./components/BtnParticipateTraining.vue').default);

Vue.component('comp-notifications', require('./components/Notifications.vue').default);

Vue.component('comp-notifications-msg', require('./components/NotificationsMsg.vue').default);

Vue.component('comp-notifications-msg-nav', require('./components/NotificationsMsgNav.vue').default);

Vue.component('toast', require('./components/Toast.vue').default);

Vue.component('post', require('./components/Post.vue').default);

Vue.component('posts-list', require('./components/PostsList.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});


// require('./jquery.min');
// require('./popper.min');
// require('./bootstrap.min');
// require('./jquery.appear');
// require('./countdown.min');
// require('./waypoints.min');
// require('./jquery.counterup.min');
// require('./wow.min');
// require('./apexcharts');
// require('./slick.min');
// require('./select2.min');
// require('./owl.carousel.min');
// require('./jquery.magnific-popup.min');
// require('./smooth-scrollbar');
// require('./lottie');
// require('./core');
// require('./charts');
// require('./animated');
// require('./kelly');
// require('./maps');
// require('./worldLow');
// require('./chart-custom');
// require('./custom');