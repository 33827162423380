<template>
    <div>
        <div v-for="post in posts" :key="post.id">
            <post :key="post.id" :id="post.id" :post="post" :user="user"></post>
        </div>
        <infinite-loading @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";
export default {
    components: { InfiniteLoading },
    props: {
        initPosts: {
            type: Array,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        ownerId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            posts : this.initPosts,
            page: 2
        };
    },
    mounted() {
        console.log(this.posts);
    },
    computed: {
    },

    methods: {
        infiniteHandler: function($state) {
            let $vm = this;
            axios
                .get("/post", {
                    params: {
                        page: this.page,
                        ownerId: this.ownerId
                    }
                })
                .then(({ data }) => {
                    if (data.data.length) {
                        this.page += 1;
                        $vm.posts = $vm.posts.concat(data.data);
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
        }
    }
};
</script>
<style lang=""></style>
