<template>
  <div class="tab-content">
    <!-- Start Conversation! -->
    <div
      v-if="room == null"
      class="tab-pane fade active show"
      id="default-block"
      role="tabpanel"
    >
      <div class="chat-start">
        <span class="iq-start-icon text-primary"
          ><i class="ri-message-3-line"></i
        ></span>
      </div>
    </div>
    <!-- End Start Conversation! -->
    <div
      v-if="room != null"
      class="tab-pane fade active show"
      id="chatbox1"
      role="tabpanel"
    >
      <div class="chat-head">
        <header
          class="
            d-flex
            justify-content-between
            align-items-center
            bg-white
            pt-3
            pr-3
            pb-3
          "
        >
          <div class="d-flex align-items-center">
            <div class="sidebar-toggle">
              <i class="ri-menu-3-line"></i>
            </div>
            <div
              v-if="room.status == 'group'"
              class="avatar chat-user-profile m-0 mr-3"
            >
              <img
                :src="'/images/default/groupe.png'"
                alt="chatuserimage"
                class="avatar-50"
              />
            </div>
            <div
              v-if="room.status == 'private'"
              class="avatar chat-user-profile m-0 mr-3"
            >
              <img
                v-if="room.friend.profile_picture_path"
                :src="room.friend.profile_picture_path"
                alt="chatuserimage"
                class="avatar-50"
              />
              <img
                v-if="!room.friend.profile_picture_path"
                :src="'/images/default/user_profile_picture.jpg'"
                alt="chatuserimage"
                class="avatar-50"
              />

              <!-- <span class="avatar-status"
                ><i class="ri-checkbox-blank-circle-fill text-success"></i
              ></span> -->
            </div>
            <h5 v-if="room.status == 'private'" class="mb-0">
              {{ room.friend.first_name }}
              {{ room.friend.last_name }}
            </h5>
            <h5 v-if="room.status == 'group'" class="mb-0">
              {{ room.name }}
            </h5>
          </div>
          <!-- membre discussion group -->
          <div
            v-if="room.status == 'group'"
            class="chat-user-detail-popup scroller"
          >
            <div class="user-profile text-center">
              <button type="submit" class="close-popup p-3">
                <i class="ri-close-fill"></i>
              </button>
              <div class="user mb-4">
                <h5 class="">Membre</h5>
              </div>
              <hr />
              <div class="chatuser-detail text-left mt-4">
                <ul class="iq-chat-ui nav flex-column nav-pills">
                  <li v-for="item in room.rooms_user" :key="item.id">
                    <a data-toggle="pill" href="#">
                      <div class="d-flex align-items-center">
                        <div class="avatar mr-2">
                          <img
                            v-if="item.user.profile_picture_path"
                            :src="item.user.profile_picture_path"
                            alt="chatuserimage"
                            class="avatar-50"
                          />
                          <img
                            v-if="!item.user.profile_picture_path"
                            :src="'/images/default/user_profile_picture.jpg'"
                            alt="chatuserimage"
                            class="avatar-50"
                          />
                          <span class="avatar-status"
                            ><i
                              class="ri-checkbox-blank-circle-fill text-success"
                            ></i
                          ></span>
                        </div>
                        <div class="chat-sidebar-name">
                          <h6 class="mb-0">
                            {{ item.user.first_name }}
                            {{ item.user.last_name }}
                          </h6>
                          <span>{{ item.status }}</span>
                        </div>
                        <div
                          class="chat-meta float-right text-center mt-2 mr-1"
                        >
                          <!-- <button class="btn btn-xs btn-danger" @click="deleteUser(item.id)"><i class="ri-delete-bin-line"></i></button> -->
                          <!-- <a href="#" class="chat-icon-delete iq-bg-primary">
                                  <i class="ri-delete-bin-line"></i>
                                </a> -->
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- end membre discussion group -->
          <div class="chat-header-icons d-flex">
            <!-- <a @click="deletechat()" class="chat-icon-delete iq-bg-primary">
              <i class="ri-delete-bin-line"></i>
              </a> -->
          </div>
        </header>
      </div>
      <div class="chat-content scroller" v-chat-scroll>
        <infinite-loading @infinite="infiniteHandler"></infinite-loading>
        <div v-for="message in messages.slice().reverse()" :key="message.id">
          <div v-if="user.id == message.sender_id" class="chat">
            <div class="chat-user">
              <a class="avatar m-0">
                <img
                  v-if="user.profile_picture_path"
                  :src="user.profile_picture_path"
                  alt="avatar"
                  class="avatar-35"
                />
                <img
                  v-if="!user.profile_picture_path"
                  :src="'/images/default/user_profile_picture.jpg'"
                  alt="avatar"
                  class="avatar-35"
                />
              </a>
              <span class="chat-time mt-1">{{ message.created_at }}</span>
            </div>
            <div class="chat-detail">
              <div class="chat-message">
                <p v-html="message.content">{{ message.content }}</p>
                <!-- <p>{{ message.date_send }}</p> -->
              </div>
            </div>
          </div>
          <div v-if="user.id != message.sender_id" class="chat chat-left">
            <div v-if="user.id != message.sender_id" class="chat-user">
              <a class="avatar m-0">
                <img
                  v-if="message.sender.profile_picture_path"
                  :src="message.sender.profile_picture_path"
                  alt="avatar"
                  class="avatar-35"
                />
                <img
                  v-if="!message.sender.profile_picture_path"
                  :src="'/images/default/user_profile_picture.jpg'"
                  alt="avatar"
                  class="avatar-35"
                />
              </a>
              <span class="chat-time mt-1">{{ message.created_at }}</span>
            </div>
            <div class="chat-detail">
              <div class="chat-message">
                <p>{{ message.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-footer p-3 bg-white">
        <div class="d-flex align-items-center">
          <!-- <div class="chat-attagement d-flex">
            <a href="#"
              ><i class="fa fa-smile-o pr-3" aria-hidden="true"></i
            ></a>
            <a href="#"
              ><i class="fa fa-paperclip pr-3" aria-hidden="true"></i
            ></a>
          </div> -->
          <input
            type="text"
            v-model="form.content"
            v-on:keyup.enter="send"
            class="form-control mr-3"
            placeholder="Tapez votre message"
          />
          <button
            v-on:click="send()"
            class="btn btn-primary d-flex align-items-center p-2"
          >
            <i class="fa fa-paper-plane-o" aria-hidden="true"></i
            ><span class="d-none d-lg-block ml-1">Envoyer</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';
Vue.use(VueChatScroll);

export default {
  name: "boxConversation",
  props: {
    pUser: {
      default: null,
    },
    pRoom: {
      default: null,
    },
  },

  data() {
    return {
      user: JSON.parse(this.pUser),
      roomID: null,
      room: JSON.parse(this.pRoom),
      messages: null,
      form: {
        content: "",
        sender_id: "",
        room_id: "",
      },
      page: 1,
      current_page: null,
      next_page: 0,
      last_page: null,
    };
  },

  beforeMounted() {},

  mounted() {
    if (this.pRoom) {
      this.room = JSON.parse(this.pRoom);
      this.roomID = this.room.id;

      this.getMessages(this.room.id);
    }

    this.$root.$on("boxConversation", (room) => {
      console.log(room);
      this.roomID = room.id;
      this.room = room;
      this.getMessages(room.id);
      if (room.id) {
        this.read(room.id);
      }

      Echo.private(`message.${this.roomID}`).listen("SendMessageEvent", (e) => {
        console.log("mssg from");
        this.getMessages(this.roomID);
      });
    });
  },

  components: {
    InfiniteLoading,
  },
  computed: {},

  methods: {
    getMessages: function (roomID) {
      console.log(roomID);
      axios.get("/getMessages/" + roomID).then((response) => {
        console.log("getMessages");
        if (response.data.length != 0) {
          this.current_page = response.data.current_page;
          this.next_page = response.data.current_page + 1;
          this.last_page = response.data.last_page;
          this.messages = response.data.data;
          console.log(this.messages);
        }
      });
    },

    read(roomID) {
      const config = {
        headers: {
          // "content-type": "multipart/form-data",
          "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      };

      axios
        .post(
          "/readMessage",
          { user_id: this.user.id, room_id: roomID },
          config
        )
        .then((response) => {
          console.log(response);
        });
    },

    async send() {
      const config = {
        headers: {
          "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      };

      if (this.form.content != "") {
        this.form.sender_id = this.user.id;
        this.form.room_id = this.roomID;

        const config = {
          headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
        };
        console.log(this.form);
        axios.post("/sendMessage", this.form, config).then((response) => {
          console.log(response);
          console.log(this.roomID);
          this.form.content = "";
          this.getMessages(this.roomID);
          console.log(6);
        });
      }
    },

    infiniteHandler($state) {
      setTimeout(() => {
        console.log("setTimeout");
        axios
          .get("/getMessages/" + this.friend + "?page=" + this.next_page)
          .then((response) => {
            console.log(response.data.data.length);
            if (response.data.data.length != 0) {
              this.current_page = response.data.current_page;
              this.next_page = response.data.current_page + 1;
              this.last_page = response.data.last_page;

              this.messages = this.messages.concat(response.data.data);
              console.log(this.messages);
              if (this.current_page == this.last_page) {
                $state.complete();
              }
              $state.loaded();
            }
            if (response.data.data.length == 0) {
              $state.complete();
              $state.loaded();
            }
          });
      }, 1000);
    },

    deletechat() {
      const config = {
        headers: {
          "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      };
      axios
        .delete("/delete-chat-room/" + this.roomID, config)
        .then((response) => {
          this.room = response.data;
          this.messages = null;
          console.log(response.data);
        });
    },
  },
};
</script>

<style></style>
