<template>
  <div
    id="post-modal-data"
    class="iq-card iq-card-block iq-card-stretch iq-card-height"
  >
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title">Créer une publication</h4>
      </div>
    </div>
    <div class="iq-card-body" data-toggle="modal" data-target="#post-modal">
      <div class="d-flex align-items-center">
        <div class="user-img">
          
          <!-- <img
            src="/images/user/1.jpg"
            alt="userimg"
            class="avatar-60 rounded-circle"
          /> -->
        </div>
        <form class="post-text ml-3 w-100" action="javascript:void();">
          <input
            type="text"
            class="form-control rounded"
            placeholder="Ecrire quelque chose ici..."
            style="border: none"
          />
        </form>
      </div>
      <hr />
      <ul class="post-opt-block d-flex align-items-center list-inline m-0 p-0">
        <li class="iq-bg-primary rounded p-2 pointer mr-3">
          <a href="#"></a>
          <img
            src="/images/small/07.png"
            alt="icon"
            class="img-fluid"
            v-on:click="state"
          />
          Photo/Fichier
        </li>
        <!-- <li class="iq-bg-primary rounded p-2 pointer mr-3">
          <a href="#"></a
          ><img src="/images/small/08.png" alt="icon" class="img-fluid" /> Tag
          Friend
        </li>
        <li class="iq-bg-primary rounded p-2 pointer mr-3">
          <a href="#"></a
          ><img src="images/small/09.png" alt="icon" class="img-fluid" />
          Feeling/Activity
        </li>
        <li class="iq-bg-primary rounded p-2 pointer">
          <div class="iq-card-header-toolbar d-flex align-items-center">
            <div class="dropdown">
              <span
                class="dropdown-toggle"
                id="post-option"
                data-toggle="dropdown"
              >
                <i class="ri-more-fill"></i>
              </span>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="post-option"
                style=""
              >
                <a class="dropdown-item" href="#">Check in</a>
                <a class="dropdown-item" href="#">Live Video</a>
                <a class="dropdown-item" href="#">Gif</a>
                <a class="dropdown-item" href="#">Watch Party</a>
                <a class="dropdown-item" href="#">Play with Friend</a>
              </div>
            </div>
          </div>
        </li> -->
      </ul>
    </div>

    <!-- model -->
    <div
      class="modal fade"
      id="post-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="post-modalLabel"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="post-modalLabel">Créer une publication</h5>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              <i class="ri-close-fill"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex align-items-center">
              <div class="user-img">
                <img
                  src="/images/default/user_profile_picture.jpg"
                  alt="userimg"
                  class="avatar-60 rounded-circle"
                />
              </div>

              <form class="post-text ml-3 w-100">
                <textarea
                  v-model="description"
                  id="desc"
                  class="form-control rounded"
                  placeholder="Ecrire quelque chose ici..."
                  style="border: none"
                ></textarea>
                <vue-dropzone
                  v-if="dropzoneState"
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  @vdropzone-file-added="fileAdded"
                  @vdropzone-removed-file="FileRemoved"
                ></vue-dropzone>
              </form>
            </div>
            <hr />
            <ul class="d-flex flex-wrap align-items-center list-inline m-0 p-0">
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <button v-on:click="state" style="border:none;color: #48a3e6;background: none;">
                    <img
                      src="/images/small/07.png"
                      alt="icon"
                      class="img-fluid"
                      v-on:click="state"
                    />
                    Photo/Fichier
                  </button>
                </div>
              </li>
              <!-- <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/08.png" alt="icon" class="img-fluid" />
                  Tag Friend
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/09.png" alt="icon" class="img-fluid" />
                  Feeling/Activity
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/10.png" alt="icon" class="img-fluid" />
                  Check in
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/11.png" alt="icon" class="img-fluid" />
                  Live Video
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/12.png" alt="icon" class="img-fluid" />
                  Gif
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/13.png" alt="icon" class="img-fluid" />
                  Watch Party
                </div>
              </li>
              <li class="col-md-6 mb-3">
                <div class="iq-bg-primary rounded p-2 pointer mr-3">
                  <img src="images/small/14.png" alt="icon" class="img-fluid" />
                  Play with Friends
                </div>
              </li> -->
            </ul>
            <hr />

            <button
              type="submit"
              class="btn btn-primary d-block w-100 mt-3"
              v-on:click="submit"
            >
              Publier
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import $ from "jquery";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    page: {
      default: null,
    },
    groupe: {
      default: null,
    },
  },
  data() {
    return {
      counter: 0,
      description: "",
      form: new FormData(),
      dropzoneState: false,
      dropzoneOptions: {
        url: "/",
        headers: {},
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
    };
  },
  mounted() {},

  methods: {
    state: function () {
      if (!this.dropzoneState) this.dropzoneState = true;
    },

    fileAdded(file) {
      this.form.append("files[" + this.counter + "]", file);
      this.counter = this.counter + 1;
    },

    FileRemoved(file) {
      for (var key of this.form.keys()) {
        if (this.form.get(key) === file) {
          this.form.delete(key);
        }
      }
    },

    submit() {
      if (this.description != "") {
        this.form.append("description", this.description);
      }
      this.form.append("group_id", this.groupe);

      this.form.append("page_id", this.page);

      axios
        .post("/post", this.form, {
          "X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]")
            .content,
          "Content-Type": "multipart/form-data",
        })
        .then(
          (response) => {
            if (response.status === 200) {
              $("#post-modal").modal("toggle");
              $(".modal-backdrop").remove();
              this.counter = 0;
              this.description = "";
              this.form = new FormData();
              this.dropzoneState = false;
              location.reload();
            }
          }).catch((e) => {
          console.log('erorooroororooror');
          console.log(e.response);
          if(e.response.status == 422){
            this.$root.$emit('showToast','danger','Erreur','Les images que vous avez téléchargées ne répondent pas aux exigences minimales');
            /*new Toast({
              message: 'Les images que vous avez téléchargées ne répondent pas aux exigences minimales',
              type: 'danger'
            });*/
          }
          else{
            this.$root.$emit('showToast','danger','Erreur','Erreur');
            /*new Toast({
              message: 'Error',
              type: 'danger'
            });*/
          }
        })
    },
  },
};
</script>