<template>
  <div class="d-flex align-items-center">
    <div class="like-data">
            <a v-on:click="doThis('Like')" class="ml-2 mr-2" data-toggle="tooltip" data-placement="top" title=""
              data-original-title="Like"><img :src="reactionIcon[0]"
              class="img-fluid" alt=""></a>
    </div>
    <div class="total-like-block ml-2 mr-3">
      <div class="dropdown">
        <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
          {{ countReaction }} Likes
        </span>
        
        <div v-if="userReaction" class="dropdown-menu">
          <div v-for="value in userReaction">
            <a class="dropdown-item text-white" href="#">{{ value.first_name }} {{ value.last_name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
    
  </div>
</template>

<script>
export default {
  props: {
    props1: {
      default: null,
    },
    props2: {
      default: null,
    },
    props3: {
      default: null,
    },
    props4: {
      default: null,
    },
  },

  data() {
    return {
      reactionIcon: JSON.parse(this.props1),
      userReaction: [],
      countReaction: 0,
      idPost: this.props4,
    };
  },

  beforeMount() {
    if (this.props3 != "" && this.props3 != null) {
      this.userReaction = JSON.parse(this.props3) ? JSON.parse(this.props3) : [];
    }
    if (this.props2 != "") {
      this.countReaction = Number(this.props2);
    }
  },

  mounted() {},

  methods: {
    doThis: function (reaction) {
      // add id post, id user, reaction(like,love...)
      let data = null;

      switch (reaction) {
        case "Like":
          data = 1;
          break;
        case "Love":
          data = 2;
          break;
        case "Happy":
          data = 3;
          break;
        case "HaHa":
          data = 4;
          break;
        case "Think":
          data = 5;
          break;
        case "Sade":
          data = 6;
          break;
        case "Lovely":
          data = 7;
          break;
      }

      axios.post("/reaction",{ post_id: this.idPost,reaction: data},{ "X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]").content,"Content-Type": "text"})
        .then((response) => {
            if (response.status === 200) {
              this.countReaction++;
              this.$set(this.userReaction, this.userReaction.length, response.data);
                //this.userReaction.push(response.data);
              console.log(this.userReaction);
              console.log(response);
            }
            else if (response.status === 201) {
              this.countReaction--;
              this.userReaction = this.userReaction.filter(item => item.first_name != response.data.first_name && item.last_name != response.data.last_name);
            }
          },
        );
    },
  },
};
</script>