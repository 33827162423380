<template>
  <div class="right-sidebar-panel p-0">
    <div class="iq-card shadow-none">
      <div class="iq-card-body p-0">

          
          <div class="media-height p-3">
                    <!-- search -->
          <div class="chat-searchbar mt-4">
            <div class="form-group chat-search-data m-0">
              <input type="text" class="form-control round" id="chat-search" v-on:keyup="search" placeholder="Rechercher">
              <i class="ri-search-line"></i>
            </div>
          </div>
          <!-- end-search -->
          <!-- result-search -->
          <div v-if="users">
            <div
              v-for="list in users"
              :key="list.id"
              class="media align-items-center mb-4"
            >
              <div class="iq-profile-avatar">
                <img v-if="list.searchable.profile_picture_path"
                  class="rounded avatar-50"
                  :src="list.searchable.profile_picture_path"
                  :alt="list.searchable.first_name"
                />
                <img v-else
                  class="rounded avatar-50"
                  src="/images/default/user_profile_picture.jpg"
                />
              </div>
              <div class="media-body ml-3">
                <h6 class="mb-0">
                  <a style="color: var(--iq-title-text);" :href="'/profile/visite/' + list.searchable.slug">{{ list.searchable.first_name }}</a>
                </h6>
                <p class="mb-0"><a style="color: #212529;" :href="'/profile/visite/' + list.searchable.slug">{{ list.searchable.last_name }}</a></p>
              </div>
              <a :href="'/chat/' + list.searchable.slug" class="btn btn-primary d-flex align-items-center p-2">
                <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <!-- <div v-else class="ml-4"> Vous n'avez aucun ami !</div> -->
        </div>
        <!-- end-result-search -->
        <div class="media-height p-3">
          <div v-if="lists" class="scroller" style="overflow-y: scroll;overflow-x: hidden; height: calc(100vh - 12rem); padding-right: 2px;">
            <div
              v-for="list in lists"
              :key="list.id"
              class="media align-items-center mb-4"
            >
            <a :href="'/profile/visite/' + list.slug">
              <div class="iq-profile-avatar" :href="'/profile/visite/' + list.slug">
                <span v-if="list.type=='admin'" class="badge badge-danger" style="position: absolute; height: 16px; width: 50px;bottom: 0px; border-radius: 0px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">ANPT</span>
                <span v-if="list.type=='startup'" class="badge badge-success" style="position: absolute; height: 16px; width: 50px;bottom: 0px; border-radius: 0px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">Startup</span>
                <span v-if="list.type=='pdp'" class="badge badge-success" style="position: absolute; height: 16px; width: 50px;bottom: 0px; border-radius: 0px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">PDP</span>
                <img v-if="list.profile_picture_path"
                  class="rounded avatar-50"
                  :src="list.profile_picture_path"
                  :alt="list.first_name"
                />
                <img v-else
                  class="rounded avatar-50"
                  src="/images/default/user_profile_picture.jpg"
                />
              </div>
              </a>
              <div class="media-body ml-3">
                <h6 style="color: var(--iq-title-text);" class="mb-0">
                  <a :href="'/profile/visite/' + list.slug">{{ list.first_name }}</a>
                </h6>
                <p class="mb-0"><a style="color: #212529;" :href="'/profile/visite/' + list.slug">{{ list.last_name }}</a></p>
              </div>
              <a :href="'/chat/' + list.slug" class="btn btn-primary d-flex align-items-center p-2">
                <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <div v-else class="ml-4"> Vous n'avez aucun ami !</div>
        </div>
        <div class="right-sidebar-toggle bg-primary mt-3">
          <i class="ri-arrow-left-line side-left-icon"></i>
          <i class="ri-arrow-right-line side-right-icon"
            ><span class="ml-3 d-inline-block">Close Menu</span></i
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    a: {
      default: null,
    },
    b: {
      default: null,
    },
    c: {
      default: null,
    },
  },
  data() {
    return {
      lists: null,
      users: null,
      pusher: null,
      app: null,
      authEndpoint: this.a,
      port: this.c,
      apps: JSON.parse(this.b),
    };
  },

  mounted() {
    
    // this.app = this.apps[0] || null;
    // this.pusher = new Pusher(this.app.key, {
    //   wsHost: this.app.host === null ? window.location.hostname : this.app.host,
    //   wsPort: this.port === null ? 6001 : this.port,
    //   wssPort: this.port === null ? 6001 : this.port,
    //   wsPath: this.app.path === null ? "" : this.app.path,
    //   disableStats: true,
    //   authEndpoint: this.authEndpoint,
    //   auth: {
    //     headers: {
    //       "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
    //         .content,
    //       "X-App-ID": this.app.id,
    //     },
    //   },
    //   enabledTransports: ["ws", "flash"],
    // });

    axios.get("/friends").then((response) => {
      // console.log("aeazedza");
      if (response.data.length != 0) {
        // console.log(response.data);
        this.lists = response.data;
      }

    });

    // setInterval(() => {
    //   axios.get("/friends").then((response) => {
    //     if (response.data.length != 0) {
    //      this.lists = response.data;
    //   }
    //   });
    // }, 1000);
  },

  methods: {
    openProfile(slug){
        axios.get(route('/profile/visite/'+slug))
        // axios.get('/profile/visite/'+slug)
    },

    search(event) {
      // setTimeout(() => {
        console.log( event.target.tagName, event.type, event.key, event.target.value);
        if(event.target.value){
          axios.get("/search-users?query="+event.target.value).then((response) => {
            console.log(response);
            this.users= response.data.data;
          });
        }else{
          this.users = null;
        }
  
      // }, 2000);
    },

    getRoom(item){
      console.log(item.searchable);
      axios.get('/getRoom/'+item.searchable.id).then((response) => {
        console.log(response);
        this.$root.$emit('boxConversation',response.data.id, response.data, item.searchable);
      });
    },
  },

  beforeDestroy() {
    clearInterval(this.lists);
  },
};
</script>